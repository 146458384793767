import { useState } from 'react'

export const useModal = <T = undefined>(initialOpened = false, cleanOnClose = true) => {
  const [opened, setOpened] = useState(initialOpened)
  const [data, setData] = useState<T | undefined>(undefined)

  const open = (tempData?: T) => {
    setData(tempData)
    setOpened(true)
  }

  const close = () => {
    if (cleanOnClose) {
      setData(undefined)
    }
    setOpened(false)
  }

  const cleanData = () => {
    setData(undefined)
  }

  return {
    data,
    setData,
    opened,
    open,
    close,
    cleanData,
  }
}
